import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";


export default class Team extends Component{
    render() {
      return(
        <div style={{backgroundColor : '#000000'}} className="about-header-team">
          <h1>
            <span className="title-two">Team</span>
          </h1>
        <div className="banner-team">
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
              <img
                  src="headshots/hgaunt.jpg"
                  className="headshot"
                  alt="Headshot of Dr Heather Gaunt"
                />
            <h1 className="text-team">Dr Heather Gaunt</h1>
            <h3 className="text">Senior Academic Programs Curator <br /> Grainger Museum</h3>
          </Grid>
          <Grid item xs={6} sm={6}>
              <img
                  src="headshots/alyons.jfif"
                  className="headshot"
                  alt="Headshot of Dr Anthony Lyons"
                />
          <h1 className="text-team">Dr Anthony Lyons</h1>
          <h3 className="text">Lecturer in Music (Interactive Composition) <br /> Faculty of Fine Arts and Music</h3>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={4}>
                <img
                  src="headshots/rsinnott.jpg"
                  className="headshot"
                  alt="Headshot of Prof Richard Sinnott"
                />
          <h1 className="text-team">Prof Richard Sinnott</h1>
          <h3 className="text">Director  <br /> Melbourne eResearch Group</h3>
          </Grid>
          <Grid item xs={6} sm={4}>
              <img
                  src="headshots/astell.png"
                  className="headshot"
                  alt="Headshot of Dr Anthony Stell"
                />  
          <h1 className="text-team">Dr Anthony Stell</h1>
          <h3 className="text">Senior Software Engineer <br /> Melbourne eResearch Group</h3>
          </Grid>
          <Grid item xs={6} sm={4}>
                <img
                  src="headshots/amohammad.jpg"
                  className="headshot"
                  alt="Headshot of Abdul Rehman Mohammad"
                />
          <h1 className="text-team">Abdul Rehman Mohammad</h1>
          <h3 className="text">Software Engineer  <br /> Melbourne eResearch Group</h3>
          </Grid>
        </Grid>
        </div>
        </div>
      );
    }
}