import Bells from "./bell.js";
import Marimba from "./marimba.js";
import React, { Component } from "react";

export default class Instrument extends Component {
  render() {
    if (this.props.instrumentstate === 0) {
      return (
        <Marimba
          sample={this.props.sample}
          cycles={this.props.cycles}
          fadeIn={this.props.fadeIn}
          fadeOut={this.props.fadeOut}
          recorder={this.props.recorder}
          delay={this.props.delay}
          dynamic={this.props.dynamic}
        ></Marimba>
      );
    } else {
      return (
        <Bells
          sample={this.props.sample}
          cycles={this.props.cycles}
          fadeIn={this.props.fadeIn}
          fadeOut={this.props.fadeOut}
          recorder={this.props.recorder}
          delay={this.props.delay}
          dynamic={this.props.dynamic}
        ></Bells>
      );
    }
  }
}

