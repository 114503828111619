import * as Tone from "tone";

function PlaySampleB(note, cycles, delay, attack, decay, recorder) {
  var player = note;
  Tone.context.resume();
  Tone.loaded().then(() => {
    player.loop = true;
    const reverb = new Tone.Reverb().toDestination();
    reverb.decay = delay;
    player.connect(reverb);
    player.connect(recorder);
    player.fadeIn = attack;
    if (decay > player.buffer.duration){
      decay = player.buffer.duration * 0.965;
    }
    player.volume.value = -20;
    player.start();
    let duration = player.buffer.duration;
    let stoppingTime = duration * 0.97 * cycles - decay;
    player.stop(`+ ${stoppingTime.toString()}`);
  });
}

export default PlaySampleB;
