import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: 'white'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'black'
  }
});

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
        classes={{
          colorPrimary: classes.colorPrimary,
          bar: classes.bar
        }}
          variant="determinate"
          {...props}
        />
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
