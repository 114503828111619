import "../styles.css";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as Tone from "tone";
import { Knob, Value, Arc } from "rc-knob";
import { Fab, Button } from "@material-ui/core";
import LinearProgressWithLabel from "./progress.js";
import IconLabelTabs from "./tabs.js";
import Grid from "@material-ui/core/Grid";
import NumberPicker from "./numberpicker.js";
import "reactjs-popup/dist/index.css";
import Instrument from "./instrument.js";
import AboveInstrument from "./aboveInstrument.js";
import FileSaver from "file-saver";
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import GetAppIcon from '@material-ui/icons/GetApp';
import initTealiumTracker from "tealium-tracker";
import ScrollToTop from "react-scroll-to-top";

const { trackEvent } = initTealiumTracker();

function mapTouchEvents(event, simulatedType) {
  //Ignore any mapping if more than 1 fingers touching
  if (event.changedTouches.length > 1) {
    return;
  }

  var touch = event.changedTouches[0];

  //--https://developer.mozilla.org/en/DOM/document.createEvent--
  let eventToSimulate = document.createEvent("MouseEvent");

  //--https://developer.mozilla.org/en-US/docs/Web/API/event.initMouseEvent--
  eventToSimulate.initMouseEvent(
    simulatedType, //type
    true, //bubbles
    true, //cancelable
    window, //view
    1, //detail
    touch.screenX, //screenX
    touch.screenY, //screenY
    touch.clientX, //clientX
    touch.clientY, //clientY
    false, //ctrlKey
    false, //altKey
    false, //shiftKey
    false, //metaKey
    0, //button
    null //relatedTarget
  );

  touch.target.dispatchEvent(eventToSimulate);
  //This ignores the default scroll behavior
  event.preventDefault();
}

class MusicPlayerv2 extends Component {
  constructor(props) {
    super(props);
    // call it again if items count changes
    this.instrument = 0;
    this.dynamic = "piu";
    this.state = {
      optionsGroups: {
        title: [
          { value: "piu", label: "p" },
          { value: "mezzo-forte", label: "mf" },
          { value: "più forte", label: "pf" }
        ]
      },
      cycles: 1,
      delay: 0,
      fadeIn: 0,
      fadeOut: 0,
      percentDone: 0,
      dynamic: "piu"
    };
    this.instrumentType = this.instrumentType.bind(this);
    this.malletType = this.malletType.bind(this);
    this.ProgressBar = this.ProgressBar.bind(this);
    this.StopRecording = this.StopRecording.bind(this);
    this.setCycles = this.setCycles.bind(this);
    this.recordingColor = this.recordingColor.bind(this);
    this.recorder = new Tone.Recorder();
    Tone.start();
    Tone.Destination.volume._maxValue = 0;
    this.recordingBlock = setTimeout(0);
    this.RecordingState = false;
    this.fadeInTracker = 0;
    this.fadeOutTracker = 0;
    this.reverbTracker = 0;
    this.cyclesTracker = 0;
    this.initialStateDelay = true;
  }

  setDelay(value) {
    this.delay = value;
    this.setState({ delay: value });
    if (this.reverbTracker === 0 & !this.initialStateDelay){
      this.reverbTracker += 1;
      setTimeout(() => {
        trackEvent({
          ga_EventCategory: (this.instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
          ga_EventAction: "Effects: Reverb",
          ga_EventLabel: this.state.delay.toFixed(1)
        }); 
        // console.log("Data sent = " + this.state.delay.toFixed(1))
        this.reverbTracker = 0 }, 
        1000);
      }
      this.initialStateDelay = false;
  }

  recordingColor(){
    return (this.RecordingState ? 'red' : 'black');
  }

  async StopRecording(recorder) {
    this.stopRecording = true;
    const downloadDiv = document.getElementById("download-link");
    const recording = await recorder.stop();
    const url = URL.createObjectURL(recording);
    ReactDOM.render(
      <Fab variant="extended" href="#" id="download-button">
        Download recording
      </Fab>,
      downloadDiv
    );
    const anchor = document.getElementById("download-button");
    anchor.download = "recording.wav";
    anchor.href = url;
  }

  async ProgressBar(value, recorder) {
    if (this.RecordingState) {
      this.RecordingState = false;
      const downloadDiv = document.getElementById("download-link");
      const recording = await recorder.stop();
      ReactDOM.render(
        <Button
          onClick={() => {
            trackEvent({
              ga_EventCategory: (this.instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
              ga_EventAction: "Recording Station",
              ga_EventLabel: 'Download'
            });
            FileSaver.saveAs(recording, "recording.wav");
          }}
          variant="outlined"
        >
          <GetAppIcon />
        </Button>,
        downloadDiv
      );
    } else {
      trackEvent({
        ga_EventCategory: (this.instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
        ga_EventAction: "Recording Station",
        ga_EventLabel: 'Record'
      });
      this.RecordingState = true;
      const downloadDiv = document.getElementById("download-link");
      ReactDOM.render(<div></div>, downloadDiv);
      let progressSpace = document.getElementById("progress-bar");
      let timeText = document.getElementById("time-text");
      ReactDOM.render(<LinearProgressWithLabel value={0} />, progressSpace);
      let songTime = 0;
      let progressIncrement = value * 16.66;
      recorder.start();
      var timer = setInterval(() => {
        songTime = songTime + (value / 60);
        let percentDone = (songTime / value) * 100;
        this.setState({percentDone : percentDone})
        if (songTime > value || !this.RecordingState) {
          ReactDOM.render(
            <LinearProgressWithLabel value={100} />,
            progressSpace
          );
          clearInterval(timer);
          clearTimeout(recordingBlock);
          
        } else {
          ReactDOM.render(
            <LinearProgressWithLabel value={percentDone} />,
            progressSpace
          );
          timeText.innerHTML = "0:" + Math.round(songTime).toString().padStart(2, '0');
        }
      }, progressIncrement);
      var recordingBlock = setTimeout(async () => {
        // the recorded audio is returned as a blob
        if (this.RecordingState) {
          var recording = await recorder.stop();
          ReactDOM.render(
            <Button
              onClick={() => {
                trackEvent({
                  ga_EventCategory: (this.instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
                  ga_EventAction: "Recording Station",
                  ga_EventLabel: 'Download'
                });
                FileSaver.saveAs(recording, "recording.wav");
              }}
              variant="outlined"
            >
              <GetAppIcon />
            </Button>,
            downloadDiv
          );
          this.RecordingState = false;
        } else {
          console.log("Timeout cleared");
          clearTimeout(recordingBlock);
        }
      }, value * 1000);
      return recordingBlock;
    }
  }

  setFadeIn(value) {
    this.setState({ fadeIn: value });
    if (this.fadeInTracker === 0){
      this.fadeInTracker += 1;
      setTimeout(() => {
        trackEvent({
          ga_EventCategory: (this.instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
          ga_EventAction: "Effects: Attacks",
          ga_EventLabel: this.state.fadeIn.toFixed(1)
        }); 
        // console.log("Data sent = " + this.state.fadeIn.toFixed(1))
        this.fadeInTracker = 0 }, 
        1000);
    }
  }

  setFadeOut(value) {
    this.setState({ fadeOut: value });
    if (this.fadeOutTracker === 0){
      this.fadeOutTracker += 1;
      setTimeout(() => {
        trackEvent({
          ga_EventCategory: (this.instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
          ga_EventAction: "Effects: Decay",
          ga_EventLabel: this.state.fadeOut.toFixed(1)
        }); 
        // console.log("Data sent = " + this.state.fadeOut.toFixed(1))
        this.fadeOutTracker = 0 }, 
        1000);
      }
  }

  componentDidMount() {
    var item = document.getElementById("row-inner");
    item.addEventListener(
      "touchstart",
      function (e) {
        mapTouchEvents(e, "mousedown");
      },
      true
    );
    item.addEventListener(
      "touchmove",
      function (e) {
        mapTouchEvents(e, "mousemove");
      },
      true
    );
    item.addEventListener(
      "touchend",
      function (e) {
        mapTouchEvents(e, "mouseup");
      },
      true
    );
    item.addEventListener(
      "touchcancel",
      function (e) {
        mapTouchEvents(e, "mouseup");
      },
      true
    );
    this.setDelay(1.0);
  }

  malletType(value) {
    let dynamicDefinition = document.getElementById("dynamic-definition");
    this.setState({dynamic : value});
    trackEvent({
      ga_EventCategory: (this.instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
      ga_EventAction: "Select dynamic",
      ga_EventLabel: value
    });
    var definitions = {
      "piu" : "<i>p</i> or piano, meaning \"quiet\"",
      "mezzo-forte" : "<i>mf</i> standing for mezzo-forte, meaning \"moderately loud\"",
      "più forte" : "<i>pf</i> standing for più forte and meaning \"more loud\""
    }
    dynamicDefinition.innerHTML = definitions[value];
  }

  setCycles(value) {
    this.setState({ cycles: Math.round(value) });
    if (this.cyclesTracker === 0){
      this.cyclesTracker += 1;
      setTimeout(() => {
        trackEvent({
          ga_EventCategory: (this.instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
          ga_EventAction: "Effects: Cycles",
          ga_EventLabel: this.state.cycles.toFixed(1)
        }); 
        // console.log("Data sent = " + this.state.cycles.toFixed(1))
        this.cyclesTracker = 0 }, 
        1000);
      }
  }

  instrumentType(value) {
    this.instrument = value;
    if (value === 0) {
      this.setState({
        optionsGroups: {
          title: [
            { value: "piu", label: "p" },
            { value: "mezzo-forte", label: "mf" },
            { value: "più forte", label: "pf" }
          ]
        }
      });
    } else {
      this.setState({
        optionsGroups: {
          title: [{ value: "piu", label: "piu" }]
        }
      });
      this.setState({ dynamic : "piu"});
    }
  }

  render() {
    return (
      <div>
      {window.innerWidth < 576 ? <ScrollToTop smooth color="#000000" />: <div></div>}
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <Grid container style={{ textAlign: "center" }} spacing={3}>
          <Grid item xs={3} sm={3}></Grid>
          <Grid item xs={6} sm={6}>
            <IconLabelTabs instrumentChange={this.instrumentType} />
            <div id="moreinfo">
              <AboveInstrument instrumentstate={this.instrument} />
            </div>{" "}
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
        </Grid>
        <Grid container spacing={1} style={{width : '100%'}}>
          <Grid
            style={{ marginLeft: "5vw", marginRight: "5vw" }}
            item
            xs={12}
            sm={12}
          >
            <Instrument
              instrumentstate={this.instrument}
              dynamicState={this.instrumentType}
              sample={this.sample}
              cycles={this.state.cycles}
              fadeIn={this.state.fadeIn}
              fadeOut={this.state.fadeOut}
              recorder={this.recorder}
              delay={this.state.delay}
              dynamic={this.state.dynamic}
            ></Instrument>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                  <div className="dynamic-inner">
                  <div>
                  <NumberPicker
                    malletChange={this.malletType}
                    dynamics={this.state.optionsGroups}
                  />
                  </div>
                  <div>
                  <h3 className="dynamic-title">Select dynamic</h3>
                  <div id="dynamic-definition"><i>p</i> or piano, meaning "quiet"</div>
                  </div>
                  </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="recording-studio">
                <h3 className="recording-studio-title">Recording Station</h3>
                  <div>
                  <div className="playingStyle-inner">
                    <div><h6 id="time-text" style={{marginRight : '3px'}}>0:00</h6></div>
                    <div id="progress-bar">
                      <LinearProgressWithLabel value={this.state.percentDone} />
                    </div>
                    <div><h6>0:30</h6></div>
                  </div>
                  <div className="record-inner">
                    <div></div>
                    <div id="record-button">
                    <Button
                    variant="outlined"
                    onClick={() => {
                      this.recordingBlock = this.ProgressBar(30, this.recorder);
                    }}
                  >
                    <FiberManualRecordSharpIcon style={{'color' :  this.recordingColor()}} />
                  </Button>
                  </div>
                    <div id="download-link"></div>
                  </div>
                  </div>
              </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            backgroundColor: "#ffffff",
            opacity: 0.6
          }}
          container
          spacing={0}
        >
        </Grid>
        <div id="row-inner">
          <Grid
            container
            spacing={0}
          >
            <Grid style={{textAlign:'center'}} item xs={6} sm={3}>
              <div className="knob">
                <div className="knob-outer">
                <p className="page-sub-title">Repeats</p>
                <h6 className="knob-text">Desired repititions of each note</h6>
                <div className="knob-inner">
                <Knob
                  size={100}
                  angleOffset={220}
                  angleRange={280}
                  min={1}
                  max={10}
                  onChange={(value) => {
                    this.setCycles(Math.round(value));
                  }}
                >
                  <Arc arcWidth={5} color="white" background="black" />
                  <Value marginBottom={40} className="value" />
                </Knob>
                </div>
                </div>
              </div>
            </Grid>

            <Grid style={{textAlign:'center'}} item xs={6} sm={3}>
              <div className="knob">
              <div className="knob-outer">
              <p className="page-sub-title">Reverb</p>
              <h6 className="knob-text">Spatial reverberation effect</h6>
              <div className="knob-inner">
                <Knob
                  size={100}
                  angleOffset={220}
                  angleRange={280}
                  min={1}
                  max={5}
                  onChange={(value) => this.setDelay(value)}
                >
                  <Arc arcWidth={5} color="white" background="black" />
                  <Value marginBottom={40} decimalPlace={1} className="value"/>
                </Knob>
                </div>
                </div>
              </div>
            </Grid>
            <Grid style={{textAlign:'center'}}  item xs={6} sm={3}>
              <div className="knob">
              <div className="knob-outer">
              <p className="page-sub-title">Attack</p>
              <h6 className="knob-text">Beginning buildup of a note</h6>
              <div className="knob-inner">
                <Knob
                  size={100}
                  angleOffset={220}
                  angleRange={280}
                  min={0}
                  max={2}
                  onChange={(value) => this.setFadeIn(value)}
                >
                  <Arc arcWidth={5} color="white" background="black" />
                  <Value marginBottom={40} className="value" decimalPlace={1} />
                </Knob>
              </div>
              </div>
              </div>
            </Grid>
            <Grid style={{textAlign:'center'}} item xs={6} sm={3}>
              <div className="knob">
              <div className="knob-outer">
              <p className="page-sub-title">Decay</p>
              <h6 className="knob-text">Fading out time of the note</h6>
              <div className="knob-inner">
                <Knob
                  size={100}
                  angleOffset={220}
                  angleRange={280}
                  min={0}
                  max={8}
                  onChange={(value) => this.setFadeOut(value)}
                >
                  <Arc arcWidth={5} color="white" background="black" />
                  <Value marginBottom={40} className="value" decimalPlace={1} />
                </Knob>
              </div>
              </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="footer">
            <h5 className="text">Copyright © The University of Melbourne 2021</h5>
      </div>
      </div>
    );
  }
}

export default MusicPlayerv2;

