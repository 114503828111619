import * as Tone from "tone";

export var counter = 0;

export var C4_bell_p = new Tone.Player(
  "./sounds/bell/C4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D4_bell_p = new Tone.Player(
  "./sounds/bell/D4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E4_bell_p = new Tone.Player(
  "./sounds/bell/E4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F4_bell_p = new Tone.Player(
  "./sounds/bell/F4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G4_bell_p = new Tone.Player(
  "./sounds/bell/G4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A4_bell_p = new Tone.Player(
  "./sounds/bell/A4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B4_bell_p = new Tone.Player(
  "./sounds/bell/B4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C5_bell_p = new Tone.Player(
  "./sounds/bell/C5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D5_bell_p = new Tone.Player(
  "./sounds/bell/D5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E5_bell_p = new Tone.Player(
  "./sounds/bell/E5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F5_bell_p = new Tone.Player(
  "./sounds/bell/F5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G5_bell_p = new Tone.Player(
  "./sounds/bell/G5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A5_bell_p = new Tone.Player(
  "./sounds/bell/A5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B5_bell_p = new Tone.Player(
  "./sounds/bell/B5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS4_bell_p = new Tone.Player(
  "./sounds/bell/CS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS4_bell_p = new Tone.Player(
  "./sounds/bell/DS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS4_bell_p = new Tone.Player(
  "./sounds/bell/FS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS4_bell_p = new Tone.Player(
  "./sounds/bell/GS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS4_bell_p = new Tone.Player(
  "./sounds/bell/AS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS5_bell_p = new Tone.Player(
  "./sounds/bell/CS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS5_bell_p = new Tone.Player(
  "./sounds/bell/DS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS5_bell_p = new Tone.Player(
  "./sounds/bell/FS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS5_bell_p = new Tone.Player(
  "./sounds/bell/GS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS5_bell_p = new Tone.Player(
  "./sounds/bell/AS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();
