import React, { Component } from "react";
import AboutHeader from "./header";
import Cobweb from "../particles/Cobweb";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";
import Team from "./team";


var styles = {
    margin: "5px"
  };


export default class About extends Component{
    render() {
        return (
        <div>
        <Cobweb color="#C0C0C0" bg={true} />
        <AboutHeader />
            <div class="parallax">
                    <div className="banner">
                    <h1>
                        <span className="title-two">The Grainger Museum</span>
                    </h1>
                    <h3>
                    <span className="text">
                    The Grainger Museum at the University of Melbourne is a dynamic teaching and learning environment, integrating an historic museum building, a diverse cultural collection, and a thematic focus privileging creativity and experimental practice in multimedia sound art. The Museum’s mission aligns with founder Percy Grainger's ideals of democratic, radical practice and experimentation, in the spheres of music and multidisciplinary sound-based creativity, as well as the power of material culture and archives to transform and enrich contemporary artistic practice.
                    </span>
                    </h3>
                    <Button
                        style={styles}
                        variant="contained"
                        color="default"
                        startIcon={<InfoIcon />}
                        href="https://grainger.unimelb.edu.au/"
                    >
                        Visit Grainger website
                    </Button>
                    </div>
            </div>
        <Team />
        <div className="footer">
              <h5 className="text">Copyright © The University of Melbourne 2021</h5>
        </div>
        </div>
        
        );
    }
}