import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import initTealiumTracker from "tealium-tracker";


const { trackEvent } = initTealiumTracker();

export default function IconLabelTabs(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.instrumentChange(newValue);
    if (newValue === 1){ 
        trackEvent({
          ga_EventCategory: "Staff Bells",
          ga_EventAction: "Overview",
        });
    }
  };

  return (
    <div>
      <Tabs
        style={{'color': '#000000'}} 
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="icon label tabs example"
        TabIndicatorProps={{style: {background:'black'}}}
      >
        <Tab value={0} label="Metal Marimba" />
        <Tab value={1} label="Staff bells" />
      </Tabs>
    </div>
  );
}
