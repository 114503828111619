import React, { Component } from "react";
import Square from "../particles/Square";

  
export default class Specs extends Component {
render() {
    return (
    <div >
    <header className="home">
        <Square style={{background : '#000000'}} bg={true} />
        <div className="banner">
        <h1>
            <span className="title-two">Technical Recommendations</span>
        </h1>
        <h3>
            <span className="text">
            This web application has been developed and optimised for desktop and tablet devices. We recommend to run this application on Google Chrome version 9.0 and above. Moreover if you
            are running this application on a Mac device, we recommend having iOS 12.5.1 and above for all the functionalities to be supported. While it may work on other devices and browsers, we cannot
            confirm that all of the functionalities will be supported as they have not been tested or optimized. 
            </span>
        </h3>
        </div>
    </header>
            <div className="footer">
            <h5 className="text">Copyright © The University of Melbourne 2021</h5>
        </div>
        </div>
    );
}
}
  