import React, { Component } from "react";
import { Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Popup from "reactjs-popup";
import initTealiumTracker from "tealium-tracker";


const { trackEvent } = initTealiumTracker();


function sendTealium(instrument){
      trackEvent({
        ga_EventCategory: (instrument === 0 ?  "Metal Marimba" : "Staff Bells"),
        ga_EventAction: "More info"
      });
}

export default class AboveInstrument extends Component {
  render() {
    if (this.props.instrumentstate === 0) {
      return (
        <div>
          <h3 className="text-app">
            The Metal marimba made by R. H. Mayland & Son, New York, USA (instrument
            makers) in the early twentieth century. Grainger Museum Collection,
            00.0065
          </h3>
          <Popup
            trigger={
              <Button
                variant="contained"
                color="default"
                startIcon={<InfoIcon onClick={() => sendTealium(this.props.instrumentstate)}/>}
              >
                <span onClick={() => sendTealium(this.props.instrumentstate)}>More Info</span>
              </Button>
            }
            modal
            nested
            contentStyle={{width:'90%'}}
          >
            {(close) =>(
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header">
                  {" "}
                  <h1 className="text"> Metal Marimba </h1>
                </div>
                <div>
                  <img
                    width={window.innerWidth > 576 ? "550" : "150"}
                    height={window.innerWidth > 576 ? "325" : "100"}
                    src="icons/marimba-full.jpg"
                    className="instrument-pic"
                    alt="the metal marimba, from a front on view of the whole instrument"
                  />
                 <img
                    width={window.innerWidth > 576 ? "550" : "150"}
                    height={window.innerWidth > 576 ? "325" : "100"}
                    src="icons/Metalmarimba.jpg"
                    className="instrument-pic"
                    alt="the metal marimba, from a side on view of the whole instrument"
                  />
                </div>
                <div className="content">
                  <h3 className="text">
                    <p>
                      Metal marimba made by R. H. Mayland & Son, New York, USA
                      (instrument makers) in the early twentieth century.
                      Grainger Museum Collection, 00.0065.
                    </p>
                    <p>
                      The Rowland H. Mayland Company manufactured a wide variety
                      of musical bells and novelties, including hand bells,
                      glockenspiels, xylophones, pitched sleighbells,
                      marimbaphones, tuning forks, and tubular chimes.
                      Established in Brooklyn, New York, in 1866, Mayland
                      continued to manufacture hand bells until World War II,
                      although the company had abandoned the other musical
                      instruments decades earlier.
                    </p>
                    This instrument, purchased from Mayland & Son by Percy
                    Grainger, has aluminium bars set above rectangular wooden
                    resonators, on a collapsible frame. It has a three-octave
                    chromatic compass. It was used in performances of Percy
                    Grainger’s composition Tribute to Foster, as single notes
                    and resonators could be removed from the instrument for
                    playing individually. Grainger wrote instructions such as
                    ‘violin II, desk I on A# key’, on the side of many
                    resonators, indicating which string desk in the orchestra
                    should bow the resonator in performance.
                  </h3>
                </div>
              </div>
            )}
          </Popup>
        </div>
      );
    } else {
      return (
        <div>
          <h3 className="text-app">
            Staff Bells, made by J. C. Deagan Inc., Chicago, USA (instrument
            maker) in 1916. Grainger Museum Collection 04.0108.
          </h3>
          <Popup
            trigger={
              <Button
                style={{ margin: "5px" }}
                variant="contained"
                color="default"
                startIcon={<InfoIcon onClick={() => sendTealium(this.props.instrumentstate)}/>}
              >
                <span onClick={() => sendTealium(this.props.instrumentstate)}>More Info</span>
              </Button>
            }
            modal
            nested
            contentStyle={{width:'90%'}}
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header">
                  {" "}
                  <h2 className="text"> Staff bells </h2>
                </div>
                <div className="images">
                  <div>
                  <img
                    width={window.innerWidth > 576 ? "550" : "150"}
                    height={window.innerWidth > 576 ? "325" : "100"}
                    src="icons/staff-bells.jpg"
                    className="instrument-pic"
                    alt="the staff bells instrument, from a front on view of the whole instrument"
                  />
                  </div>
                  <div>
                  <img
                    width={window.innerWidth > 576 ? "550" : "150"}
                    height={window.innerWidth > 576 ? "325" : "100"}
                    src="icons/19044_0249.jpg"
                    className="instrument-pic"
                    alt="the staff bells instrument, from a side on view showing a close up of the handle on each bells"
                  />
                  </div>
                </div>
                <div className="content">
                  <h3 className="text">
                    <p>
                      Staff Bells, made by J. C. Deagan Inc., Chicago, USA
                      (instrument maker) in 1916. Grainger Museum Collection
                      04.0108.
                    </p>
                    <p>
                      Two sets of bells with closed tubular resonators were
                      co-designed by Grainger and innovative American instrument
                      maker J.C. Deagan in 1916. Over the sets the bells
                      encompass four (chromatic) octaves, beginning on C.
                      Grainger delighted in the church-bell-like character of
                      the Staff Bells, which he described as ‘similar to “Swiss
                      hand bells” in tone’, and exploited the rich contrasts in
                      sound achieved by using mallets ranging from hard to soft.
                      Grainger considered that the Staff Bells were the only
                      bells capable of being heard with clarity above the sound
                      of a full orchestra. These bells were used in the first
                      performance of Grainger’s masterpiece, The Warriors, in
                      1917.
                    </p>
                    Ella Grainger became an expert player of her husband’s
                    compositions using the bells and steel marimba, under
                    Percy’s tutelage, despite having no formal musical training.
                    She played the bells on tours across America in the period
                    1929-33, and in Australia and New Zealand in 1934-35. A
                    photograph of the Staff Bells was published in the Adelaide
                    News during the Australian tour, with the caption: ‘Two sets
                    of unusual instruments that will be introduced by Mr. Percy
                    Grainger in the S.A. Orchestral concert in the town hall
                    tonight. The bells are used because they are the only
                    percussion instrument that can be heard while the orchestra
                    is playing loudly, and the glasses are used in the chorus to
                    produce a low, murmuring sound like bees’.
                  </h3>
                  <p className="text-caption"><i>Photography Peter Casamento</i></p> 
                </div>
              </div>
            )}
          </Popup>
        </div>
      );
    }
  }
}
