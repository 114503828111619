import React, { Component } from "react";
import Picker from "react-scrollable-picker";

export default class NumberPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueGroups: {
        title: "piu"
      },
      optionGroups: this.props.dynamics
    };
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      }
    }));
    this.props.malletChange(value);
  };

  NavigationPreloadManager;
  render() {
    const { valueGroups } = this.state;

    return (
      <div>
        <Picker
          optionGroups={this.props.dynamics}
          valueGroups={valueGroups}
          onChange={this.handleChange}
          height={150}
        />
      </div>
    );
  }
}
