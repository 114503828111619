import React, { Component } from "react";
import Square from "./particles/Square";
import Button from "@material-ui/core/Button";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import InfoIcon from "@material-ui/icons/Info";

var styles = {
  margin: "5px"
};

export default class Header extends Component {
  render() {
    return (
      <div>
      <header className="home">
        <Square style={{background : '#000000'}} bg={true} />
        <div className="banner">
          <h1>
            <span className="title-one">Living</span>
            <span className="title-two">Instruments</span>
          </h1>
          <h3>
            <span className="title-one">Living</span>
            <span className="title-two">Instruments</span>
            <span className="text">
              {" "}
              is an interdisciplinary collaboration project that digitally
              preserves and transforms historic and rare instruments in the
              Grainger Museum collection into playable virtual instruments for
              interactive display and creative engagement.{" "}
            </span>
            <span className="title-one">Living</span>
            <span className="title-two">Instruments </span>
            <span className="text">
              brings contemporary sound making practices together with digital
              instrument design.
            </span>
          </h3>

          <Button
            style={styles}
            variant="contained"
            color="default"
            startIcon={<InfoIcon />}
            href="/about"
          >
            About
          </Button>

          <Button
            variant="contained"
            color="default"
            startIcon={<MusicNoteIcon />}
            style={styles}
            href="/instruments"
          >
            Play
          </Button>
        </div>
      </header>
              <div className="footer">
              <h5 className="text">Copyright © The University of Melbourne 2021</h5>
        </div>
        </div>
    );
  }
}
