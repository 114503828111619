import React from "react";
import "./styles.css";
import SideBar from "./musicapp/sidebar";
import { Route, Switch } from "react-router-dom";
import Header from "./Home";
import About from "./about/About";
import MusicPlayerv2 from "./musicapp/MusicPlayer.js";
import Research from "./research/Research";
import Specs from "./tech/Specs";

export default function App() {
  return (
    <div>
      <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
      <main>
        <Switch>
          <Route path="/" component={Header} exact />
          <Route path="/about" component={About} exact />
          <Route path="/instruments" component={MusicPlayerv2} exact />
          <Route path="/research" component={Research} exact />
          <Route path="/specifications" component={Specs} exact />
        </Switch>
      </main>
    </div>
  );
}
