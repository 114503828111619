import React, { Component } from "react";

export default class AboutHeader extends Component{
    render() {
        return (
        <header className="about-header">
        <div className="banner">
        <h1>
            <span className="title-two">About</span>
          </h1>
        <h3>
            <span className="text">
            The Grainger Museum is transforming historically rare and unique instruments into interactive and playable virtual instruments. The{" "}
            </span>
            <span className="title-one">Living</span>
            <span className="title-two">Instruments</span>
            <span className="text">
            {" "}project – a collaboration with the University of Melbourne’s Faculty of Fine Arts and Music and the Melbourne eResearch Group, School of Computing & Information Systems – involves mapping real instruments (by sampling their sound) to new tactile digital interfaces that anyone can use.
            </span>
          </h3>
        </div>
        </header>
        );
    }
}