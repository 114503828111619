import React, { Component } from "react";
import Cobweb from "../particles/Cobweb";
import ReactPlayer from 'react-player';




export default class Research extends Component{
    render() {
        return(
        <div>
            <Cobweb color="#C0C0C0" bg={true} />  
            <header className="form-header">
            <div className="banner">      
            <h1>
            <span className="title-two">Research</span>
            </h1>
            <h3>
            <span className="text">
            The <span className="title-one">Living</span><span className="title-two">Instruments</span> project began in 2019 as an interdisciplinary research and curriculum collaboration led by Dr Anthony Lyons from the Faculty of Fine Arts and Music, with students studying in the Bachelor of Music (Interactive Composition) program in 2019. This playlist by students and staff reflects this sampling and instrument building process, and importantly examines how contemporary makers are not just preservers of sound but creative agents. Using these digital sampled instruments, (and a little creative freedom!), this playlist represents a range of new works reflecting the sampling process and a sound world from our own time.
            </span>
            </h3>
            <h2><span className="title-two">Some of our work</span></h2>
            <ReactPlayer width="100%" url="https://soundcloud.com/user-839269279/sets/living-instruments-creative-sound-works"/>
            </div>
            </header>
            <div className="footer">
              <h5 className="text">Copyright © The University of Melbourne 2021</h5>
        </div>
        </div>
        );
    }
}