import React, { Component } from "react";
import PlaySampleB from "./playSampleB.js";
import * as notes from "./notes.js";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import initTealiumTracker from "tealium-tracker";

const { trackEvent } = initTealiumTracker();


var noteMappings = {
  p: {
    C3: notes.C3_marimba_p,
    D3: notes.D3_marimba_p,
    E3: notes.E3_marimba_p,
    F3: notes.F3_marimba_p,
    G3: notes.G3_marimba_p,
    A3: notes.A3_marimba_p,
    B3: notes.C3_marimba_p,
    C4: notes.C4_marimba_p,
    D4: notes.D4_marimba_p,
    E4: notes.E4_marimba_p,
    F4: notes.F4_marimba_p,
    G4: notes.G4_marimba_p,
    A4: notes.A4_marimba_p,
    B4: notes.B4_marimba_p,
    C5: notes.C5_marimba_p,
    D5: notes.D5_marimba_p,
    E5: notes.E5_marimba_p,
    F5: notes.F5_marimba_p,
    G5: notes.G5_marimba_p,
    A5: notes.A5_marimba_p,
    B5: notes.B5_marimba_p,
    C6: notes.C6_marimba_p,
    CS3: notes.CS3_marimba_p,
    DS3: notes.DS3_marimba_p,
    FS3: notes.FS3_marimba_p,
    GS3: notes.GS3_marimba_p,
    AS3: notes.AS3_marimba_p,
    CS4: notes.CS4_marimba_p,
    DS4: notes.DS4_marimba_p,
    FS4: notes.FS4_marimba_p,
    GS4: notes.GS4_marimba_p,
    AS4: notes.AS4_marimba_p,
    CS5: notes.CS5_marimba_p,
    DS5: notes.DS5_marimba_p,
    FS5: notes.FS5_marimba_p,
    GS5: notes.GS5_marimba_p,
    AS5: notes.AS5_marimba_p
  },
  mf: {
    C3: notes.C3_marimba_mf,
    D3: notes.D3_marimba_mf,
    E3: notes.E3_marimba_mf,
    F3: notes.F3_marimba_mf,
    G3: notes.G3_marimba_mf,
    A3: notes.A3_marimba_mf,
    B3: notes.C3_marimba_mf,
    C4: notes.C4_marimba_mf,
    D4: notes.D4_marimba_mf,
    E4: notes.E4_marimba_mf,
    F4: notes.F4_marimba_mf,
    G4: notes.G4_marimba_mf,
    A4: notes.A4_marimba_mf,
    B4: notes.B4_marimba_mf,
    C5: notes.C5_marimba_mf,
    D5: notes.D5_marimba_mf,
    E5: notes.E5_marimba_mf,
    F5: notes.F5_marimba_mf,
    G5: notes.G5_marimba_mf,
    A5: notes.A5_marimba_mf,
    B5: notes.B5_marimba_mf,
    C6: notes.C6_marimba_mf,
    CS3: notes.CS3_marimba_mf,
    DS3: notes.DS3_marimba_mf,
    FS3: notes.FS3_marimba_mf,
    GS3: notes.GS3_marimba_mf,
    AS3: notes.AS3_marimba_mf,
    CS4: notes.CS4_marimba_mf,
    DS4: notes.DS4_marimba_mf,
    FS4: notes.FS4_marimba_mf,
    GS4: notes.GS4_marimba_mf,
    AS4: notes.AS4_marimba_mf,
    CS5: notes.CS5_marimba_mf,
    DS5: notes.DS5_marimba_mf,
    FS5: notes.FS5_marimba_mf,
    GS5: notes.GS5_marimba_mf,
    AS5: notes.AS5_marimba_mf
  },
  f: {
    C3: notes.C3_marimba_pf,
    D3: notes.D3_marimba_pf,
    E3: notes.E3_marimba_pf,
    F3: notes.F3_marimba_pf,
    G3: notes.G3_marimba_pf,
    A3: notes.A3_marimba_pf,
    B3: notes.C3_marimba_pf,
    C4: notes.C4_marimba_pf,
    D4: notes.D4_marimba_pf,
    E4: notes.E4_marimba_pf,
    F4: notes.F4_marimba_pf,
    G4: notes.G4_marimba_pf,
    A4: notes.A4_marimba_pf,
    B4: notes.B4_marimba_pf,
    C5: notes.C5_marimba_pf,
    D5: notes.D5_marimba_pf,
    E5: notes.E5_marimba_pf,
    F5: notes.F5_marimba_pf,
    G5: notes.G5_marimba_pf,
    A5: notes.A5_marimba_pf,
    B5: notes.B5_marimba_pf,
    C6: notes.C6_marimba_pf,
    CS3: notes.CS3_marimba_pf,
    DS3: notes.DS3_marimba_pf,
    FS3: notes.FS3_marimba_pf,
    GS3: notes.GS3_marimba_pf,
    AS3: notes.AS3_marimba_pf,
    CS4: notes.CS4_marimba_pf,
    DS4: notes.DS4_marimba_pf,
    FS4: notes.FS4_marimba_pf,
    GS4: notes.GS4_marimba_pf,
    AS4: notes.AS4_marimba_pf,
    CS5: notes.CS5_marimba_pf,
    DS5: notes.DS5_marimba_pf,
    FS5: notes.FS5_marimba_pf,
    GS5: notes.GS5_marimba_pf,
    AS5: notes.AS5_marimba_pf
  }
};
const StyledCircular = withStyles({
  colorPrimary: {
    color: "#000000"
  }
})(CircularProgress);

function PlayingDynamic(style) {
  if (style === "piu") {
    return "p";
  } else if (style === "mezzo-forte") {
    return "mf";
  } else {
    return "f";
  }
}

export default class Marimba extends Component {

  handleChange(note) {
    //this.props.sample(note);
    trackEvent({
      ga_EventCategory: "Metal Marimba",
      ga_EventAction: "Play",
      ga_EventLabel: note
    });
    PlaySampleB(
      noteMappings[PlayingDynamic(this.props.dynamic)][note],
      this.props.cycles,
      this.props.delay,
      this.props.fadeIn,
      this.props.fadeOut,
      this.props.recorder
    );
  }

  componentDidMount() {
    var timer = setInterval(() => {
      if (notes.counter === 111) {
        this.forceUpdate();
        clearInterval(timer);
      } else {
        this.forceUpdate();
      }
    }, 1000);
  }

  render() {
    if (notes.counter < 110) {
      let percentDone = (notes.counter / 110) * 100;
      return (
        <div className="carouselDivSpinner">
          <div></div>
          <div className="spinnerDiv">
            <StyledCircular color="primary" size={80} value={parseInt(percentDone.toFixed(0))} variant='determinate' />
          </div>
          <div></div>
        </div>
      );
    } else {
      return (
        <div className="marimba-back">
        <div className="container-top">
            <div 
            className="note note-1"
            onClick={() => this.handleChange("CS3")}
            >
                <h3 className="note-text-top">C#3</h3>
            </div>
            <div 
            className="note note-2"
            onClick={() => this.handleChange("DS3")}
            >
                <h3 className="note-text-top">D#3</h3>
            </div>
            <div className="note-empty"></div>
            <div 
            className="note note-3"
            onClick={() => this.handleChange("FS3")}
            >
                <h3 className="note-text-top">F#3</h3>
            </div>
            <div 
            className="note note-4"
            onClick={() => this.handleChange("GS3")}
            >
                <h3 className="note-text-top">G#3</h3>
            </div>
            <div 
            className="note note-5" 
            onClick={() => this.handleChange("AS3")}
            >
                <h3 className="note-text-top">A#3</h3>
            </div>
            <div className="note-empty"></div>
            <div 
            className="note note-5"
            onClick={() => this.handleChange("CS4")}
            >
                <h3 className="note-text-top">C#4</h3>
            </div>
            <div 
            className="note note-6"
            onClick={() => this.handleChange("DS4")}
            >
                <h3 className="note-text-top">D#4</h3>
            </div>
            <div className="note-empty"></div>
            <div 
            className="note note-7" 
            onClick={() => this.handleChange("FS4")}
            >
                <h3 className="note-text-top">F#4</h3>
            </div>
            <div 
            className="note note-8"
            onClick={() => this.handleChange("GS4")}
            >
                <h3 className="note-text-top">G#4</h3>
            </div>
            <div 
            className="note note-9"
            onClick={() => this.handleChange("AS4")}
            >
                <h3 className="note-text-top">A#4</h3>
            </div>
            <div className="note-empty"></div>
            <div 
            className="note note-10"
             onClick={() => this.handleChange("CS5")}
             >
                <h3 className="note-text-top">C#5</h3>
            </div>
            <div 
            className="note note-11"
            onClick={() => this.handleChange("DS5")}
            >
                <h3 className="note-text-top">D#5</h3>
            </div>
            <div className="note-empty"></div>
            <div className="note note-12"
            onClick={() => this.handleChange("FS5")}>
                <h3 className="note-text-top">F#5</h3>
            </div>
            <div className="note note-38" onClick={() => this.handleChange("GS5")}>
                <h3 className="note-text-top">G#5</h3>
            </div>
            <div className="note note-38" onClick={() => this.handleChange("AS5")}>
                <h3 className="note-text-top">A#5</h3>
            </div>
        </div>
        <div className="container">
            <div className="note note-13" onClick={() => this.handleChange("C3")}>
                <h3 className="note-text">C3</h3>
            </div>
            <div className="note note-14" onClick={() => this.handleChange("D3")}>
                <h3 className="note-text">D3</h3>
            </div>
            <div className="note note-15" onClick={() => this.handleChange("E3")}>
                <h3 className="note-text">E3</h3>
            </div>
            <div className="note note-16" onClick={() => this.handleChange("F3")}>
                <h3 className="note-text">F3</h3>
            </div>
            <div className="note note-17" onClick={() => this.handleChange("G3")}>
                <h3 className="note-text">G3</h3>
            </div>
            <div className="note note-18" onClick={() => this.handleChange("A3")}>
                <h3 className="note-text">A3</h3>
            </div>
            <div className="note note-19" onClick={() => this.handleChange("B3")}>
                <h3 className="note-text">B3</h3>
            </div>
            <div className="note note-20" onClick={() => this.handleChange("C4")}>
                <h3 className="note-text">C4</h3>
            </div>
            <div className="note note-21" onClick={() => this.handleChange("D4")}>
                <h3 className="note-text">D4</h3>
            </div>
            <div className="note note-22" onClick={() => this.handleChange("E4")}>
                <h3 className="note-text">E4</h3>
            </div>
            <div className="note note-23" onClick={() => this.handleChange("F4")}>
                <h3 className="note-text">F4</h3>
            </div>
            <div className="note note-24" onClick={() => this.handleChange("G4")}>
                <h3 className="note-text">G4</h3>
            </div>
            <div className="note note-25" onClick={() => this.handleChange("A4")}>
                <h3 className="note-text">A4</h3>
            </div>
            <div className="note note-26" onClick={() => this.handleChange("B4")}>
                <h3 className="note-text">B4</h3>
            </div>
            <div className="note note-27" onClick={() => this.handleChange("C5")}>
                <h3 className="note-text">C5</h3>
            </div>
            <div className="note note-28" onClick={() => this.handleChange("D5")}>
                <h3 className="note-text">D5</h3>
            </div>
            <div className="note note-29" onClick={() => this.handleChange("E5")}>
                <h3 className="note-text">E5</h3>
            </div>
            <div className="note note-30" onClick={() => this.handleChange("F5")}>
                <h3 className="note-text">F5</h3>
            </div>
            <div className="note note-31" onClick={() => this.handleChange("G5")}>
                <h3 className="note-text">G5</h3>
            </div>
            <div className="note note-32" onClick={() => this.handleChange("A5")}>
                <h3 className="note-text">A5</h3>
            </div>
            <div className="note note-33" onClick={() => this.handleChange("B5")}>
                <h3 className="note-text">B5</h3>
            </div>
            <div className="note note-34" onClick={() => this.handleChange("C6")}>
                <h3 className="note-text">C6</h3>
            </div>
        </div>
        </div>
      );
    }
  }
}

