import React from "react";
import { slide as Menu } from "react-burger-menu";

export default (props) => {
  return (
    // Pass on our props
    <Menu {...props}>
      <div className="logo">
        <img className="menu-item" 
            src="The-University-of-Melbourne-Logo.png" 
            alt="the university of melbourne logo"
            width="150"
            height="110"/>
      </div>
      <div className="logo">
        <img className="menu-item" 
            src="grangier-logo.png"  
            alt="the grainger museum logo" 
            width="150"
            height="90"/>
      </div>
      <a className="menu-item" href="/">
        Home
      </a>

      <a className="menu-item" href="/about">
        About us
      </a>

      <a className="menu-item" href="/instruments">
        App
      </a>

      <a className="menu-item" href="/research">
        Research
      </a>

      <a className="menu-item" href="/specifications">
        Technical
      </a>

    </Menu>
  );
};
