import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import PlaySampleB from "./playSampleB.js";
import * as notes from "./notesBell.js";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import initTealiumTracker from "tealium-tracker";


const { trackEvent } = initTealiumTracker();

var noteMappings = {
  p: {
    C4: notes.C4_bell_p,
    D4: notes.D4_bell_p,
    E4: notes.E4_bell_p,
    F4: notes.F4_bell_p,
    G4: notes.G4_bell_p,
    A4: notes.A4_bell_p,
    B4: notes.B4_bell_p,
    C5: notes.C5_bell_p,
    D5: notes.D5_bell_p,
    E5: notes.E5_bell_p,
    F5: notes.F5_bell_p,
    G5: notes.G5_bell_p,
    A5: notes.A5_bell_p,
    B5: notes.B5_bell_p,
    CS4: notes.CS4_bell_p,
    DS4: notes.DS4_bell_p,
    FS4: notes.FS4_bell_p,
    GS4: notes.GS4_bell_p,
    AS4: notes.AS4_bell_p,
    CS5: notes.CS5_bell_p,
    DS5: notes.DS5_bell_p,
    FS5: notes.FS5_bell_p,
    GS5: notes.GS5_bell_p,
    AS5: notes.AS5_bell_p
  }
};

function startAnimation(target) {
  let animation = anime.timeline({
    duration: 450,
    easing: "easeInOutSine",
    direction: "alternate"
  });
  animation
    .add({
      targets: target,
      translateY: -10,
      duration: 150
    })
    .add({
      targets: target,
      translateX: 10,
      duration: 150
    })
    .add({
      targets: target,
      translateX: -10,
      duration: 150
    });
}

function PlayingDynamic(style) {
  if (style === "piu") {
    return "p";
  } else if (style === "mezzo-forte") {
    return "mf";
  } else {
    return "f";
  }
}

const StyledCircular = withStyles({
  colorPrimary: {
    color: "#ffffff"
  }
})(CircularProgress);

export default class Bells extends Component {
  handleChange(note) {
    trackEvent({
      ga_EventCategory: "Staff Bells",
      ga_EventAction: "Play",
      ga_EventLabel: note
    });
    PlaySampleB(
      noteMappings[PlayingDynamic(this.props.dynamic)][note],
      this.props.cycles,
      this.props.delay,
      this.props.fadeIn,
      this.props.fadeOut,
      this.props.recorder
    );
  }

  componentDidMount() {
    var timer = setInterval(() => {
      if (notes.counter === 24) {
        // number of sample notes of the instrument
        this.forceUpdate();
        clearInterval(timer);
      } else {
        this.forceUpdate();
      }
    }, 1000);
  }

  render() {
    if (notes.counter < 23) {
      let percentDone = (notes.counter / 23) * 100;
      // number of sample notes of the instrument subtrated by one
      return (
        <div className="carouselDivSpinner">
          <div></div>
          <div>
            <StyledCircular color="primary" size={80} value={percentDone.toFixed(0)} variant='determinate' />
          </div>
          <div></div>
        </div>
      );
    } else {
      return (
        <div className="bell-main">
          <div className="bell-sidebar"></div>
          <div className="bell-middle">
            <div className="bell-five">
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellCS5");
                    this.handleChange("CS5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellCS5"
                  viewBox="0 0 16 16"
                  id="shuffled-notes"
                >
                  <text x="27.5%" y="60%" className="notes">
                    C#5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellDS5");
                    this.handleChange("DS5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellDS5"
                  viewBox="0 0 16 16"
                >
                  <text x="27.5%" y="60%" className="notes">
                    D#5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellFS5");
                    this.handleChange("FS5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellFS5"
                  viewBox="0 0 16 16"
                  id="shuffled-notes-fs5"
                >
                  <text x="27.5%" y="60%" className="notes">
                    F#5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellGS5");
                    this.handleChange("GS5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellGS5"
                  viewBox="0 0 16 16"
                  id="shuffled-notes-gs5"
                >
                  <text x="27.5%" y="60%" className="notes">
                    G#5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellAS5");
                    this.handleChange("AS5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellAS5"
                  viewBox="0 0 16 16"
                  id="shuffled-notes-as5"
                >
                  <text x="27.5%" y="60%" className="notes">
                    A#5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
            </div>
            <div className="bell-bar-h"></div>
            <div className="bell-seven">
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellC5");
                    this.handleChange("C5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellC5"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    C5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellD5");
                    this.handleChange("D5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellD5"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    D5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellE5");
                    this.handleChange("E5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellE5"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    E5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellF5");
                    this.handleChange("F5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellF5"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    F5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellG5");
                    this.handleChange("G5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellG5"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    G5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellA5");
                    this.handleChange("A5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellA5"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    A5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellB5");
                    this.handleChange("B5");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellB5"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    B5
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
            </div>
            <div className="bell-bar-h"></div>
            <div className="bell-five">
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellCS4");
                    this.handleChange("CS4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellCS4"
                  viewBox="0 0 16 16"
                  id="shuffled-notes"
                >
                  <text x="27.5%" y="60%" className="notes">
                    C#4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellDS4");
                    this.handleChange("DS4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellDS4"
                  viewBox="0 0 16 16"
                >
                  <text x="27.5%" y="60%" className="notes">
                    D#4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellFS4");
                    this.handleChange("FS4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellFS4"
                  viewBox="0 0 16 16"
                  id="shuffled-notes-fs5"
                >
                  <text x="27.5%" y="60%" className="notes">
                    F#4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellGS4");
                    this.handleChange("GS4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellGS4"
                  viewBox="0 0 16 16"
                  id="shuffled-notes-gs5"
                >
                  <text x="27.5%" y="60%" className="notes">
                    G#4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellAS4");
                    this.handleChange("AS4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellAS4"
                  viewBox="0 0 16 16"
                  id="shuffled-notes-as5"
                >
                  <text x="27.5%" y="60%" className="notes">
                    A#4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
            </div>
            <div className="bell-bar-h"></div>
            <div className="bell-seven">
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellC4");
                    this.handleChange("C4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellC4"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    C4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellD4");
                    this.handleChange("D4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellD4"
                  viewBox="0 0 16 16"
                >
                  <text x="27.5%" y="60%" className="notes">
                    D4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellE4");
                    this.handleChange("E4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellE4"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    E4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellF4");
                    this.handleChange("F4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellF4"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    F4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellG4");
                    this.handleChange("G4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellG4"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    G4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellA4");
                    this.handleChange("A4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellA4"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    A4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
              <div>
                <svg
                  onClick={() => {
                    startAnimation(".bi-bellB4");
                    this.handleChange("B4");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-bellB4"
                  viewBox="0 0 16 16"
                >
                  <text x="35%" y="60%" className="notes">
                    B4
                  </text>
                  <path
                    className="bell"
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </div>
            </div>
            <div className="bell-bar-h"></div>
          </div>
          <div className="bell-sidebar"></div>
        </div>
      );
    }
  }
}
