import * as Tone from "tone";

export var counter = 0;

export var C3_marimba_p = new Tone.Player(
  "./sounds/marimba/C3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D3_marimba_p = new Tone.Player(
  "./sounds/marimba/D3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();


export var E3_marimba_p = new Tone.Player(
  "./sounds/marimba/E3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F3_marimba_p = new Tone.Player(
  "./sounds/marimba/F3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G3_marimba_p = new Tone.Player(
  "./sounds/marimba/G3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A3_marimba_p = new Tone.Player(
  "./sounds/marimba/A3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B3_marimba_p = new Tone.Player(
  "./sounds/marimba/B3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C4_marimba_p = new Tone.Player(
  "./sounds/marimba/C4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D4_marimba_p = new Tone.Player(
  "./sounds/marimba/D4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E4_marimba_p = new Tone.Player(
  "./sounds/marimba/E4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F4_marimba_p = new Tone.Player(
  "./sounds/marimba/F4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G4_marimba_p = new Tone.Player(
  "./sounds/marimba/G4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A4_marimba_p = new Tone.Player(
  "./sounds/marimba/A4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B4_marimba_p = new Tone.Player(
  "./sounds/marimba/B4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C5_marimba_p = new Tone.Player(
  "./sounds/marimba/C5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D5_marimba_p = new Tone.Player(
  "./sounds/marimba/D5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E5_marimba_p = new Tone.Player(
  "./sounds/marimba/E5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F5_marimba_p = new Tone.Player(
  "./sounds/marimba/F5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G5_marimba_p = new Tone.Player(
  "./sounds/marimba/G5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A5_marimba_p = new Tone.Player(
  "./sounds/marimba/A5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B5_marimba_p = new Tone.Player(
  "./sounds/marimba/B5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C6_marimba_p = new Tone.Player(
  "./sounds/marimba/C6-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS3_marimba_p = new Tone.Player(
  "./sounds/marimba/CS3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS3_marimba_p = new Tone.Player(
  "./sounds/marimba/DS3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS3_marimba_p = new Tone.Player(
  "./sounds/marimba/FS3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS3_marimba_p = new Tone.Player(
  "./sounds/marimba/GS3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS3_marimba_p = new Tone.Player(
  "./sounds/marimba/AS3-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS4_marimba_p = new Tone.Player(
  "./sounds/marimba/CS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS4_marimba_p = new Tone.Player(
  "./sounds/marimba/DS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS4_marimba_p = new Tone.Player(
  "./sounds/marimba/FS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS4_marimba_p = new Tone.Player(
  "./sounds/marimba/GS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS4_marimba_p = new Tone.Player(
  "./sounds/marimba/AS4-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS5_marimba_p = new Tone.Player(
  "./sounds/marimba/CS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS5_marimba_p = new Tone.Player(
  "./sounds/marimba/DS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS5_marimba_p = new Tone.Player(
  "./sounds/marimba/FS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS5_marimba_p = new Tone.Player(
  "./sounds/marimba/GS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS5_marimba_p = new Tone.Player(
  "./sounds/marimba/AS5-p.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C3_marimba_mf = new Tone.Player(
  "./sounds/marimba/C3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D3_marimba_mf = new Tone.Player(
  "./sounds/marimba/D3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E3_marimba_mf = new Tone.Player(
  "./sounds/marimba/E3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F3_marimba_mf = new Tone.Player(
  "./sounds/marimba/F3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G3_marimba_mf = new Tone.Player(
  "./sounds/marimba/G3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A3_marimba_mf = new Tone.Player(
  "./sounds/marimba/A3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B3_marimba_mf = new Tone.Player(
  "./sounds/marimba/B3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C4_marimba_mf = new Tone.Player(
  "./sounds/marimba/C4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D4_marimba_mf = new Tone.Player(
  "./sounds/marimba/D4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E4_marimba_mf = new Tone.Player(
  "./sounds/marimba/E4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F4_marimba_mf = new Tone.Player(
  "./sounds/marimba/F4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G4_marimba_mf = new Tone.Player(
  "./sounds/marimba/G4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A4_marimba_mf = new Tone.Player(
  "./sounds/marimba/A4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B4_marimba_mf = new Tone.Player(
  "./sounds/marimba/A4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C5_marimba_mf = new Tone.Player(
  "./sounds/marimba/C5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D5_marimba_mf = new Tone.Player(
  "./sounds/marimba/D5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E5_marimba_mf = new Tone.Player(
  "./sounds/marimba/E5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F5_marimba_mf = new Tone.Player(
  "./sounds/marimba/F5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G5_marimba_mf = new Tone.Player(
  "./sounds/marimba/G5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A5_marimba_mf = new Tone.Player(
  "./sounds/marimba/A5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B5_marimba_mf = new Tone.Player(
  "./sounds/marimba/B5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C6_marimba_mf = new Tone.Player(
  "./sounds/marimba/C6-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS3_marimba_mf = new Tone.Player(
  "./sounds/marimba/CS3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS3_marimba_mf = new Tone.Player(
  "./sounds/marimba/DS3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS3_marimba_mf = new Tone.Player(
  "./sounds/marimba/FS3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS3_marimba_mf = new Tone.Player(
  "./sounds/marimba/GS3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS3_marimba_mf = new Tone.Player(
  "./sounds/marimba/AS3-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS4_marimba_mf = new Tone.Player(
  "./sounds/marimba/CS4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS4_marimba_mf = new Tone.Player(
  "./sounds/marimba/DS4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS4_marimba_mf = new Tone.Player(
  "./sounds/marimba/FS4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS4_marimba_mf = new Tone.Player(
  "./sounds/marimba/GS4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS4_marimba_mf = new Tone.Player(
  "./sounds/marimba/AS4-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS5_marimba_mf = new Tone.Player(
  "./sounds/marimba/CS5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS5_marimba_mf = new Tone.Player(
  "./sounds/marimba/DS5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS5_marimba_mf = new Tone.Player(
  "./sounds/marimba/FS5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS5_marimba_mf = new Tone.Player(
  "./sounds/marimba/GS5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS5_marimba_mf = new Tone.Player(
  "./sounds/marimba/AS5-mf.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C3_marimba_pf = new Tone.Player(
  "./sounds/marimba/C3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D3_marimba_pf = new Tone.Player(
  "./sounds/marimba/D3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E3_marimba_pf = new Tone.Player(
  "./sounds/marimba/E3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F3_marimba_pf = new Tone.Player(
  "./sounds/marimba/F3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G3_marimba_pf = new Tone.Player(
  "./sounds/marimba/G3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A3_marimba_pf = new Tone.Player(
  "./sounds/marimba/A3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B3_marimba_pf = new Tone.Player(
  "./sounds/marimba/B3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C4_marimba_pf = new Tone.Player(
  "./sounds/marimba/C4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D4_marimba_pf = new Tone.Player(
  "./sounds/marimba/D4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E4_marimba_pf = new Tone.Player(
  "./sounds/marimba/E4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F4_marimba_pf = new Tone.Player(
  "./sounds/marimba/F4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G4_marimba_pf = new Tone.Player(
  "./sounds/marimba/G4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A4_marimba_pf = new Tone.Player(
  "./sounds/marimba/A4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B4_marimba_pf = new Tone.Player(
  "./sounds/marimba/B4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C5_marimba_pf = new Tone.Player(
  "./sounds/marimba/C5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var D5_marimba_pf = new Tone.Player(
  "./sounds/marimba/D5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var E5_marimba_pf = new Tone.Player(
  "./sounds/marimba/E5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var F5_marimba_pf = new Tone.Player(
  "./sounds/marimba/F5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var G5_marimba_pf = new Tone.Player(
  "./sounds/marimba/G5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var A5_marimba_pf = new Tone.Player(
  "./sounds/marimba/A5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var B5_marimba_pf = new Tone.Player(
  "./sounds/marimba/B5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var C6_marimba_pf = new Tone.Player(
  "./sounds/marimba/C6-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS3_marimba_pf = new Tone.Player(
  "./sounds/marimba/CS3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS3_marimba_pf = new Tone.Player(
  "./sounds/marimba/DS3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS3_marimba_pf = new Tone.Player(
  "./sounds/marimba/FS3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS3_marimba_pf = new Tone.Player(
  "./sounds/marimba/GS3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS3_marimba_pf = new Tone.Player(
  "./sounds/marimba/AS3-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS4_marimba_pf = new Tone.Player(
  "./sounds/marimba/CS4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS4_marimba_pf = new Tone.Player(
  "./sounds/marimba/DS4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS4_marimba_pf = new Tone.Player(
  "./sounds/marimba/FS4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS4_marimba_pf = new Tone.Player(
  "./sounds/marimba/GS4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS4_marimba_pf = new Tone.Player(
  "./sounds/marimba/AS4-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var CS5_marimba_pf = new Tone.Player(
  "./sounds/marimba/CS5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var DS5_marimba_pf = new Tone.Player(
  "./sounds/marimba/DS5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var FS5_marimba_pf = new Tone.Player(
  "./sounds/marimba/FS5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var GS5_marimba_pf = new Tone.Player(
  "./sounds/marimba/GS5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();

export var AS5_marimba_pf = new Tone.Player(
  "./sounds/marimba/AS5-f.wav",
  () => {
    counter += 1;
  }
).toDestination();
